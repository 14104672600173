export const data1 = [
  {
    name: "Name of the parties to the Contract (optional)",
    placeholder:
      "Name the parties along with roles, separated by commas. For example - John Smith (Licensor), ABC Limited (Licensee).",
    id: "parties",
  },
  {
    name: " Description of Intellectual Property: (optional)",
    placeholder:
      "What is the Intellectual Property being licensed? For example: The right to screen the film “Barbie”.",
    id: "description",
  },
  {
    name: " Term of the license : (optional)",
    placeholder:
      "What is the period during which the IP is being licensed? Specify dates, a period or in perpetuity. ",
       id: "licensing_term",
  },
  {
    name: " Territory of the License: (optional)",
    placeholder:"Name specific territory where the License is applicable.",
     id: "territory",
  }
]
    export const data5=[
    {
      name: "Exclusivity: (optional)",
      placeholder1:"Exclusive",
      placeholder2:"Non-exclusive ",
      id: "exclusivity",
    }
  ]

 export const data3=[
  {
    name: "Sub-Licensing rights: (optional) ",
    placeholder:
      "Defaults to no sub-licensing rights. ",
    id: "sub_licensing_rights",
  },
  {
    name: "Fees and payments: (optional)",
    placeholder:
      "Mention the consideration, and whether it should be a one-time payment or periodic (monthly or yearly) license fee.",
    id: "fees_and_payments",
  },
  {
    name: "Other terms: (optional)",
    placeholder:
      "Mention if there are any other specific terms to be included in the Contract.",
    id: "other_terms",
  },
 
  {
    name: "Governing Law: (optional)",
    placeholder:
      "Mention the name of your country or state whose laws will apply. Examples: UAE. California. ",
       id: "governing_law",
  },
  {
    name: " Jurisdiction: (optional)",
    placeholder:
      "Name your city whose courts will resolve disputes (Example: Tokyo. Hong Kong. London)     ", 
      id: "jurisdiction",
  },

]


export const noteforusers =
    "While most of the fields are optional, it is good to fill all relevant fields. Better your text inputs, more detailed and accurate the draft! Feel free to use simple and minimum, 'to the point' inputs, and TipsyTom will do the drafting for you. Don’t worry about the text you put in here, they will remain even after you have generated the document in case you need to change or update anything and re-generate, and also in your Document Library! The tone, style and drafting in this Scripter is designed for use in Indian courts."


export const formdata = [
    {
        name: "Case details",
        group: [
            {
                name: "Mention full names of the Courts where the petition is to be filed:",
                element: "input-table",
                id: "",
                table: { 
                    "id": "table1",
                    "name": "Table 1",
                    "headers": [
                        { "label": "Sl. No." },
                        { "label": "Court" },
                    ],
                },
                required: false,
                optional: false,
            },
            {
                name: "Case Number: ",
                placeholder: "CC. No. 123 of 2023",
                id: "case_number",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "Any particular interim relief that may be sought by the Respondent(s):",
                placeholder: "The Respondent may attempt to obtain an ex parte interim injunction on the disputed property",
                id: "interim_relief",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
        ],
    },
    {
        name: "Mention details of Petitioner(s) for whom the Petition is being drafted.",
        group: [
            {
                name: "Petitioner's Name",
                element: "input-table",
                id: "",
                table: { // from here I need to work this give a array
                    "id": "table2",
                    "name": "Table 2",
                    "headers": [
                        { "label": "Sl. No." },
                        { "label": "Petitioner Name" },
                    ],
                },
                required: false,
                optional: false,
            },
            {
                name: "Petitioner Number",
                element: "input-table",
                id: "",
                table: { // from here I need to work this give a array
                    "id": "table3",
                    "name": "Table 3",
                    "headers": [
                        { "label": "Sl. No." },
                        { "label": "Petitioner Number" },
                    ],
                },
                required: false,
                optional: false,
            },
            {
                name: "Petitioner's Address",
                element: "input-table",
                id: "",
                table: { // from here I need to work this give a array
                    "id": "table4",
                    "name": "Table 4",
                    "headers": [
                        { "label": "Sl. No." },
                        { "label": "Petitioner's Address" },
                    ],
                },
                required: false,
                optional: false,
            },
            {
                name: "Whether the Petitioner is a body corporate:",
                // placeholder: "body_corporate",
                id: "body_corporate",
                required: false,
                optional: false,
                group:
                    [
                        {
                            name: "Yes",
                            element: "input-radio",
                            type: "radio",
                            nameAtt: "bodyCorporate",
                            id: "body_corporate_Yes",
                        },
                        {
                            name: "No",
                            element: "input-radio",
                            type: "radio",
                            nameAtt: "bodyCorporate",
                            id: "body_corporate_No",
                        },
                        {
                            name: "Which Petitioner is the Authorized Representative for the Petitioner above?",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table5",
                                "name": "Table 5",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "AR Petitioner" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },
                        {
                            name: "Which Petitioner holds the Power of Attorney for the above Petitioner?",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table6",
                                "name": "Table 6",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "POA Petitioner" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },
                        {
                            name: "Petitioner's Age",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table7",
                                "name": "Table 7",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "Petititioner Age" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },
                        {
                            name: "Petitioner's Profession",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table8",
                                "name": "Table 8",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "Petititioner Profession" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },
                        {
                            name: "Petitioner's Gender",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table9",
                                "name": "Table 9",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "Petitioner's Gender" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },
                        {
                            name: "Does any other Petitioner hold the Power of Attorney for the Petitioner above? If yes, please mention the Petitioner Number.",
                            element: "input-table",
                            id: "",
                            table: { // from here I need to work this give a array
                                "id": "table10",
                                "name": "Table 10",
                                "headers": [
                                    { "label": "Sl. No." },
                                    { "label": "Does" },
                                ],
                            },
                            required: false,
                            optional: false,
                        },

                    ],
            }
        ],
    },
    {
        name: "Caveat Respondent details:",
        element: "input-table",
        id: "",
        table: { // from here I need to work this give a array
            "id": "table11",
            "name": "Table 12",
            "headers": [
                { "label": "Sl. No." },
                { "label": "Respondent Name" },
            ],
        },
        required: false,
        optional: false,
    },
    {
        name: "Advocate Details:",
        group: [
            {
                name: "Name of advocate:",
                placeholder: "Hari Kumar",
                id: "advocate_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "Address:",
                placeholder: "B-53, DLF Phase 1, Noida",
                id: "advocate_address",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "Name of advocates in vakalatnama:",
                placeholder: "list of strings names like [Advocate1, Advocate2]",
                id: "other_advocate_names",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "Place:",
                placeholder: "Pune",
                id: "advocate_city",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
        ],
    },
];

export const fieldNames = [
    // Court_1,
    // gender_complainant,
    // petitioner_number,
    // complaint_date,
    // criminal_case_number,
    // PS_Name,
    // respondent_name,
    // petitioner_name,
    // date_of_application,
    // petitioner_residence_city,
    // gender_petitioner,
    // criminal_antecedents,
    // petitioner_profession_reputation,
    // petitioner_circumstances,
    // crim_offence_numbers,
    // seven_years_imprisonment,
    // allegation_facts,
    // complaint_facts,
    // court_name,
    // court_place,
    // advocate_name,
    // advocate_residence_city
];


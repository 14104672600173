export const data1 = [
    {
        name: " Company’s preferred form of contract for engaging the employee",
        placeholder1: "Offer Letter ",
        placeholder2: "Employment Agreement",
        id: "employement_agreement_type",
    }]

export const data2 = [
    {
        name: "Name the parties to the contract : (optional)",
        placeholder: "Name the parties along with roles, separated by commas. For example - John Smith (employee), ABC Limited (employer).",
        id: "parties",
    },
    {
        name: "Term of employment : (optional)",
        placeholder: "Mention start and end dates or a period.",
        id: "term_period",
    },
    {
        name: "Compensation and benefits to be paid to the Employee: (optional)",
        placeholder: "Compensation to be paid to the employee for the services provided. Payment due dates, withholding of taxes, reimbursements etc. may also be mentioned.",
        id: "compensation",
    },

    {
        name: " Termination (optional)",
        placeholder: "Mention the events that may lead to termination of the agreement and notice period, if any.",
        id: "termination_triggers",
    },
    {
        name: " Any other terms? (optional)",
        placeholder: "Add any other terms that you think might be relevant. ",
        id: "other_terms",
    },
    {
        name: "Governing Law: (optional)",
        placeholder: "Mention the name of your country or state whose laws will apply. Examples: India. UAE. California.         ",
        id: "governing_law",
    },
    {
        name: "Jurisdiction:  (optional)",
        placeholder: "Name your city whose courts will resolve disputes (Example: Delhi. Tokyo. London. San Francisco.)",
        id: "jurisdiction",
    }]

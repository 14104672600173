import React, { useEffect, useState } from 'react'
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import axios from 'axios';
import UserService from '../../services/UserService';
import Loader from "react-js-loader"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserDetails, getNotification, getCheckPricing } from '../../Redux/Action/action';
const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env
function PaymentSuccess() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        getTransactionId()
        //need to remove all the cached API responses to start fresh
        localStorage.removeItem(UserService.getUserId() + "StatusKey");
        localStorage.removeItem(UserService.getUserId() + "StatusKeyPricing");
        localStorage.removeItem(UserService.getUserId() + "StatusKeyPricingData");
        localStorage.removeItem(UserService.getUserId() + "StatusKeySubscriptions");
    }, [])
    const getPlanDetails = async () => {
        if (UserService.isLoggedIn()) {
            try {
                setLoader(true)
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                };
                const getResponse = await axios.post(`${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                    null,
                    { headers: headers })
                if (getResponse.status === 200) {
                    dispatch(getUserDetails(getResponse.data))
                    const download = getResponse.data.user_credit_summary.num_remaining_downloads
                    dispatch(getNotification({
                        message: "Welcome to the TipsyTom community!",
                        type: "success"
                    }))
                }

                else if (getResponse.status === 401) {
                    dispatch(getNotification({
                        message: "Session expired! Plesae log in again",
                        type: "default"
                    }))
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/payment_success` })
                    }, 3000)
                }
            }
            catch (err) {
                console.log("Error", err);
                dispatch(getNotification({
                    message: "Network issue. Please refresh the page or try again aftersometime.",
                    type: "default"
                }))
                navigate("/app/payment_failed")
            }
            setLoader(false)
        }
        else {
            dispatch(getNotification({
                message: "Session expired! Plesae log in again",
                type: "default"
            }))
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/payment_success` })
            }, 3000)
        }
        // setTimeout(() => preventFromCopyPaste(), 10)
    }

    const getTransactionId = async () => {
        if (UserService.isLoggedIn()) {
            const transactionDataStorage = JSON.parse(sessionStorage.getItem("data"))
            // console.log(transactionDataStorage);
            sessionStorage.clear()
            // setLoader(true)
            try {
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                };
                const getResp = await axios.post(`${REACT_APP_CREATE_AGREEMENT_API}/get_transaction_id?session_id=${transactionDataStorage.session}`,
                    null,
                    { headers: headers })
                if (getResp.status === 200) {
                    // console.log(getResp.data);
                    await createSubscription(getResp.data, transactionDataStorage.subscriptionId)
                    await getPlanDetails()
                    //subscription_price_from_db
                    // amount
                    // stripe_amount
                }
            }
            catch (err) {
                console.log(err);
                navigate("/app/payment_failed")
            }
            setLoader(false)
        }
        else {
            dispatch(getNotification({
                message: "Session expired! Plesae log in again",
                type: "default"
            }))
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/payment_success` })
            }, 3000)
        }
    }

    const createSubscription = async (transactionObject, subscriptionID) => {
        const transactionID = transactionObject.id
        // const timeStamp = transactionObject.created
        const stripeMode = transactionObject.object
        const paymentProductId = transactionObject.plan.id
        const amount = (transactionObject.plan.amount) / 100 //2900
        const currencyCode = transactionObject.plan.currency.toLowerCase() //check USD usd
        const userId = UserService.getUserId()
        const subscriptionId = subscriptionID
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access_token": UserService.getToken()
            };
            const getResp = await axios.post(`${REACT_APP_CREATE_AGREEMENT_API}/create_subscription?txn_id=${transactionID}&stripe_mode=${stripeMode}&payment_product_id=${paymentProductId}&amount=${amount}&currency_code=${currencyCode}&keycloak_user_id=${userId}&subscription_id=${subscriptionId}`,
                null,
                { headers: headers })
            if (getResp.status === 200) {
                setSuccess(true)
                dispatch(getCheckPricing(false))
            }
            else navigate("/app/payment_filed")
        }
        catch (err) {
            console.log(err);
            navigate("/*")
        }
    }

    if (loader) {
        return (
            <div style={{ marginTop: "30vh" }} className='md:col-span-2 md:mt-0' >
                <Loader type="bubble-spin" bgColor={"#000000"} title={"Loading..."} color={'#000000'} size={100} />
            </div >
        )
    }
    else {
        return (
            <div className={success ? "" : "d-none"} style={{ textAlign: "center", marginTop: "50px" }}>
                <CheckCircleOutlined style={{ fontSize: "64px", color: "#52c41a" }} />
                <h1 className='text-success' style={{ margin: "20px 0" }}>Payment Successful...</h1>
                <p className='text-secondary' style={{ marginBottom: "20px" }}>
                    Payment Successful...
                    Thank you. Your transaction has been completed successfully. You will receive an email confirmation.
                </p>
                <Link className='btn btn-success' to="/app">
                    Back to Home
                </Link>
            </div>
        )
    }
}

export default PaymentSuccess
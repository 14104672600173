export const noteforusers =
    "While most of the fields are optional, it is good to fill all relevant fields. Better your text inputs, more detailed and accurate the draft! Feel free to use simple and minimum, 'to the point' inputs, and TipsyTom will do the drafting for you. Don’t worry about the text you put in here, they will remain even after you have generated the document in case you need to change or update anything and re-generate, and also in your Document Library! The tone, style and drafting in this Scripter is designed for use by Indian companies."

// export const formdata = [
//     {
//         name: "Mention the name of the Company:",
//         heading: true,
//         subHeading: false,
//         placeholder: "ABC Private Limited",
//         id: "company",
//         type: "text",
//         element: "input-text",
//         required: false,
//         optional: false,
//     },
//     {
//         name: "Whether the meeting is held virtually:",
//         heading: true,
//         subHeading: true,
//         element: "input-heading",
//         id: "virtual_meeting",
//         required: false,
//         optional: false,
//     }





//         group:
//     [
//         {
//             name: "Yes",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "virtualMeeting",
//             id: "virtual_meeting_Yes",
//         },
//         {
//             name: "No",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "virtualMeeting",
//             id: "virtual_meeting_No",
//         },
//     ],
//     },
// {
//     name: "Whether it is annual meeting or extraordinay meeting:",
//         id: "meeting_class",
//             group:
//     [
//         {
//             name: "Annual General Meeting",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "meetingClass",
//             id: "meeting_class_Anual",
//         },
//         {
//             name: "Extraordinary General Meeting",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "meetingClass",
//             id: "meeting_class_General",
//         },
//     ],
//     },
// {
//     name: "Describe the resolution type:",
//         id: "resolution_type",
//             group:
//     [
//         {
//             name: "Special Resolution",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "resolutionType",
//             id: "resolution_typeSpecial",
//         },
//         {
//             name: "Ordinary Resolution",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "resolutionType",
//             id: "resoluti_Specialon_Ordinary",
//         },
//     ],
//     },
// {
//     name: "Enter name of members:",
//         element: "input-table",
//             id: "",
//                 table: [
//                     {
//                         "id": "table1",
//                         "name": "Table 1",
//                         "headers": [
//                             { "label": "Sl. No." },
//                             { "label": "Member" },
//                         ],
//                         "rows": [
//                             {
//                                 "id": "row1",
//                                 "cells": [
//                                     { "id": "Member_1", "value": "" },
//                                 ]
//                             },
//                         ]
//                     },
//                 ]
// },
// {
//     name: "Mention the agenda of meeting:",
//         id: "Detail", //need to ask
//             group: [
//                 {
//                     name: "Description:",
//                     group: [
//                         {
//                             name: "Declaration of Dividends",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Declaration of Dividends",
//                             id: "Detail1",
//                         },
//                         {
//                             name: "Appointment of director in place of retiring director",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Appointment of director in place of retiring director",
//                             id: "Detail2",
//                         },
//                         {
//                             name: "Appointment of Auditors",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Appointment of Auditors",
//                             id: "Detail3",
//                         },
//                         {
//                             name: "Adoption of financial statements",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Adoption of financial statements",
//                             id: "Detail4",
//                         },
//                         {
//                             name: "Consent for private placement",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Consent for private placement",
//                             id: "Detail5",
//                         },
//                         {
//                             name: "Approval for related party transactions",
//                             element: "input-checkbox",
//                             type: "checkbox",
//                             nameAtt: "Approval for related party transactions",
//                             id: "Detail6",
//                         },

//                     ]
//                 },
//                 {
//                     name: "",
//                     id: "",
//                     table: [
//                         {
//                             "id": "table1",
//                             "name": "Table 1",
//                             "headers": [
//                                 { label: "Sl. No." },
//                                 { label: "Lists Of Agends" },
//                                 { label: "Description" },
//                             ],
//                             "rows": [
//                                 {
//                                     "id": "row1",
//                                     "cells": [
//                                         {
//                                             id: "agenda_input_1",
//                                             value: "",
//                                         },
//                                         {
//                                             id: "agenda_description_input_1",
//                                             value: "",
//                                             placeholder: "The Company has not carried on any business since its incorporation in 2016. It is in the best interests of the members and the Company to apply for striking off the name under section 248 of the Companies Act, 2013."
//                                         },
//                                     ]
//                                 },

//                             ]
//                         },
//                     ],
//                 }

//             ]

// },
// {
//     name: "Enter name of members:",
//         id: "",
//             table: [
//                 {
//                     "id": "table1",
//                     "name": "Table 1",
//                     "headers": [
//                         { "label": "Sl. No." },
//                         { "label": "Member" },
//                     ],
//                     "rows": [
//                         {
//                             "id": "row1",
//                             "cells": [
//                                 { "id": "Member_1", "value": "" },
//                             ]
//                         },
//                     ]
//                 },
//             ],
//     },
// {
//     name: "Describe the resolution:",
//         id: "",
//             table: "table",
//     },
// {
//     name: "Select how the resolution is passed:",
//         id: "",
//             group:
//     [
//         {
//             name: "Unanimously",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "",
//             id: "",
//         },
//         {
//             name: "Special majority",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "",
//             id: "",
//         },
//         {
//             name: "Simple majority",
//             element: "input-radio",
//             type: "radio",
//             nameAtt: "",
//             id: "",
//         },
//     ],
//     },
// {
//     name: "Date of circulation of notice:",
//         placeholder: "",
//             id: "",
//                 type: "date",
//                     element: "input-text",
//                         required: false,
//                             optional: false,
//     },
// {
//     name: "Date of the meeting:",
//         placeholder: "",
//             id: "",
//                 type: "date",
//                     element: "input-text",
//                         required: false,
//                             optional: false,
//     },
// ];

export const fieldNames = [

];

export const formdata = [
    {
        name: "Mention the name of the Company:",
        placeholder: "ABC Private Limited",
        id: "company",
        type: "text",
        element: "input-text",
        required: false,
        optional: false,
    },
    {
        name: "Whether the meeting is held virtually:",
        id: "virtual_meeting",
        group:
            [
                {
                    name: "Yes",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "virtualMeeting",
                    id: "virtual_meeting_Yes",
                },
                {
                    name: "No",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "virtualMeeting",
                    id: "virtual_meeting_No",
                },
            ],
    },
    {
        name: "Whether it is annual meeting or extraordinay meeting:",
        id: "meeting_class",
        group:
            [
                {
                    name: "Annual General Meeting",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "meetingClass",
                    id: "meeting_class_Anual",
                },
                {
                    name: "Extraordinary General Meeting",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "meetingClass",
                    id: "meeting_class_General",
                },
            ],
    },
    {
        name: "Describe the resolution type:",
        id: "resolution_type",
        group:
            [
                {
                    name: "Special Resolution",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "resolutionType",
                    id: "resolution_typeSpecial",
                },
                {
                    name: "Ordinary Resolution",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "resolutionType",
                    id: "resoluti_Specialon_Ordinary",
                },
            ],
    },
    {
        name: "Enter name of members:",
        element: "input-table",
        id: "",
        table: { // from here I need to work this give a array
            "id": "table1",
            "name": "Table 1",
            "headers": [
                { "label": "Sl. No." },
                { "label": "Member" },
            ],
        },

    },
    {
        name: "Meeting Agenda:",
        element: "input-table",
        id: "Detail", //need to ask
        table: { // from here I need to work this give a array
            "id": "table1",
            "name": "Table 2",
            "headers": [
                { "label": "Agenda" },
                { "label": "Agenda Title" },
                { "label": "Detail" },
                { "label": "Resolution" },
                { "label": "Voting Details" },
            ],
            appearanceNo: 0,
            listElement: "Agenda Title",
            lists: [
                "Declaration of Dividends",
                "Appointment of director in place of retiring director",
                "Appointment of Auditors",
                "Adoption of financial statements",
                "Consent for private placement",
                "Approval for related party transactions",
            ]
        },
        // group: [
        //     {
        //         name: "Description:",
        //         group: [
        //             {
        //                 name: "Declaration of Dividends",
        //                 element: "input-selectbox",
        //                 type: "checkbox",
        //                 nameAtt: "Declaration of Dividends",
        //                 id: "Detail1",
        //             },
        //             {
        //                 name: "Appointment of director in place of retiring director",
        //                 element: "input-selectbox",
        //                 type: "checkbox",
        //                 nameAtt: "Appointment of director in place of retiring director",
        //                 id: "Detail2",
        //             },
        //             {
        //                 name: "Appointment of Auditors",
        //                 element: "input-selectbox",
        //                 type: "checkbox",
        //                 nameAtt: "Appointment of Auditors",
        //                 id: "Detail3",
        //             },
        //             {
        //                 name: "Adoption of financial statements",
        //                 element: "input-selectbox",
        //                 type: "checkbox",
        //                 nameAtt: "Adoption of financial statements",
        //                 id: "Detail4",
        //             },
        //             {
        //                 name: "Consent for private placement",
        //                 element: "input-selectbox",
        //                 type: "checkbox",
        //                 nameAtt: "Consent for private placement",
        //                 id: "Detail5",
        //             },
        //             {
        //                 name: "Approval for related party transactions",
        //                 element: "input-checkbox",
        //                 type: "checkbox",
        //                 nameAtt: "Approval for related party transactions",
        //                 id: "Detail6",
        //             },

        //         ]
        //     },
        //     {
        //         name: "",
        //         id: "",
        //         table: [
        //             {
        //                 "id": "table1",
        //                 "name": "Table 1",
        //                 "headers": [
        //                     { label: "Sl. No." },
        //                     { label: "Lists Of Agends" },
        //                     { label: "Description" },
        //                 ],
        //                 "rows": [
        //                     {
        //                         "id": "row1",
        //                         "cells": [
        //                             {
        //                                 id: "agenda_input_1",
        //                                 value: "",
        //                             },
        //                             {
        //                                 id: "agenda_description_input_1",
        //                                 value: "",
        //                                 placeholder: "The Company has not carried on any business since its incorporation in 2016. It is in the best interests of the members and the Company to apply for striking off the name under section 248 of the Companies Act, 2013."
        //                             },
        //                         ]
        //                     },

        //                 ]
        //             },
        //         ],
        //     }

        // ]

    },
    {
        name: "",
        id: "",
        table: [
            {
                "id": "table1",
                "name": "Table 1",
                "headers": [
                    { "label": "Resolution Number" },
                    { "label": "Resolution details" },
                ],
                "rows": [
                    {
                        "id": "row1",
                        "cells": [
                            {
                                "id": "Resolution_text_1",
                                "value": "",
                                placeholder: "The Members by way of a special resolution accorded their consent to make an application for strking off the name of the Company."
                            },
                        ]
                    },
                ]
            },
        ],
    },
    {
        name: "Describe the resolution:",
        id: "",
        table: "table",
    },
    {
        name: "Select how the resolution is passed:",
        id: "resolution_passing",
        group:
            [
                {
                    name: "Unanimously",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "",
                    id: "",
                },
                {
                    name: "Special majority",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "",
                    id: "",
                },
                {
                    name: "Simple majority",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "",
                    id: "",
                },
            ],
    },
    {
        name: "Date of circulation of notice:",
        placeholder: "",
        id: "notice_date",
        type: "date",
        element: "input-text",
        required: false,
        optional: false,
    },
    {
        name: "Date of the meeting:",
        placeholder: "",
        id: "meeting_date",
        type: "date",
        element: "input-text",
        required: false,
        optional: false,
    },
];
import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";

import Popup from "./Popup"

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import {
    checkBoxArrayOfLease,
    checkBoxArrayOfOther,
    checkBoxArrayOfService,
    firstPart,
    secondPart,
} from "../Agreement_Data/DraftAgreementFormData";
import FooterOthers from './FooterOthers';


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const DraftAgreement = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const [checkBoxString, setCheckBoxString] = useState([]);
    const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [other, setOther] = useState(true);
    const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("New File");
    const [tagStore, setTagStore] = useState([]);
    const [handleMode, setHandleMode] = useState(false);
    const [promt, setPromt] = useState(false)
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })

    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage()
        resetButtonView()
        if (UserService.isLoggedIn()) getDataFromLibrary()
    }, []);

    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [checkBoxString, tagStore]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])



    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem("masterDraftObject"))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            document.getElementById("other").value = masterStorageObject.type
            document.getElementById("partiesName").value = masterStorageObject.parties
            document.getElementById("favourParty").value = masterStorageObject.favouredParty
            document.getElementById("governingLaw").value = masterStorageObject.governingLaw
            document.getElementById("jurisdiction").value = masterStorageObject.jurisdiction
            document.getElementById("specify").value = masterStorageObject.specify
            document.getElementById("subjectMatter").value = masterStorageObject.subjectMatter
            document.getElementById("defaultProvision").value = masterStorageObject.defaultProvision
            getCheckboxClicked(masterStorageObject.checkboxSelections, ".checkbox-class-other")
        }
    }
    const saveDatatoStorage = () => {
        const type = document.getElementById("other").value;
        setFileName(type);
        const parties = document.getElementById("partiesName").value;
        const favouredParty = document.getElementById("favourParty").value;
        const governingLaw = document.getElementById("governingLaw").value;
        const jurisdiction = document.getElementById("jurisdiction").value;
        const startDate = document.getElementById("startDate").value;
        const endtDate = document.getElementById("endDate").value;
        const specify = document.getElementById("specify").value;
        const subjectMatter = document.getElementById("subjectMatter").value;
        const defaultProvision = document.getElementById("defaultProvision").value;
        const checkboxSelections = checkBoxString.join(",");

        const fileName = document.getElementById("input-file-name").value
        const masterStorageObject = {
            type: type,
            parties: parties,
            favouredParty: favouredParty,
            governingLaw: governingLaw,
            jurisdiction: jurisdiction,
            specify: specify,
            subjectMatter: subjectMatter,
            defaultProvision: defaultProvision,
            checkboxSelections: checkboxSelections,

            tags: tagStore,
            renameString: fileName
        }
        localStorage.setItem("masterDraftObject", JSON.stringify(masterStorageObject))
    }
    const getDataFromLibrary = () => {
        // let selector;   //for selecting which class is it so that further I can target to that array for clicking
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name)
            if (outputJSONfromLibrary.file_tags && outputJSONfromLibrary.file_tags != null && outputJSONfromLibrary.file_tags != "null") setTagStore(outputJSONfromLibrary.file_tags.split(";"))

            setOther(true)
            // setTimeout(() => {
            //     // console.log(document.getElementById("other"));
            // }, 100)
            // // document.getElementById("other").value = outputJSONfromLibrary.api_input.tipsytom_input.agreement_type
            document.getElementById("other").value = outputJSONfromLibrary.api_input.tipsytom_input.agreement_type
            document.getElementById("partiesName").value = outputJSONfromLibrary.api_input.tipsytom_input.parties
            document.getElementById("favourParty").value = outputJSONfromLibrary.api_input.tipsytom_input.favoured_party
            document.getElementById("governingLaw").value = outputJSONfromLibrary.api_input.tipsytom_input.governing_law
            document.getElementById("jurisdiction").value = outputJSONfromLibrary.api_input.tipsytom_input.jurisdiction
            document.getElementById("startDate").value = outputJSONfromLibrary.api_input.tipsytom_input.start_date
            document.getElementById("endDate").value = outputJSONfromLibrary.api_input.tipsytom_input.end_date
            document.getElementById("specify").value = outputJSONfromLibrary.api_input.tipsytom_input.term_period
            document.getElementById("subjectMatter").value = outputJSONfromLibrary.api_input.tipsytom_input.subject_matter
            document.getElementById("defaultProvision").value = outputJSONfromLibrary.api_input.tipsytom_input.default_agreement_settings
            getCheckboxClicked(outputJSONfromLibrary.api_input.tipsytom_input.checkbox_selections, ".checkbox-class-other")
        }
    }

    const getCheckboxClicked = async (checkboxString, selector) => {
        setTimeout(() => {
            const checkboxArrayFromTipsyInput = checkboxString.split(",")
            const checkboxClassNodeList = document.querySelectorAll(selector)
            checkboxArrayFromTipsyInput.forEach(element => {
                checkboxClassNodeList.forEach((ele) => {
                    if (ele.innerText === element) ele.click()
                })
            });
        }, 300)

    }

    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
        if (window.innerWidth <= "600") {
            document.querySelector(".selectMobileRemover1").classList.remove("pr-0")
        }
    }

    const preventFromCopyPaste = () => {
        const edit = document.getElementById("edit");
        if (edit) {
            edit.addEventListener("copy", preventDefault);
            edit.addEventListener("cut", preventDefault);

            function preventDefault(e) {
                e.preventDefault();
            }
        }
    };

    const getPlanDetails = async (value) => {
        if (UserService.isLoggedIn()) {
            try {
                if (!value === "noRefresh") setLoaderCredit(true); // wanna keep the form datas so using this
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                };
                const getResponse = await axios.post(
                    `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                    null,
                    { headers: headers }
                );
                if (getResponse.status === 200) {
                    // console.log(getResponse.data);
                    dispatch(getUserDetails(getResponse.data));
                    const download = getResponse.data.user_credit_summary.num_remaining_downloads;
                    // dispatch(
                    //     getNotification({
                    //         message: `You have ${download} ${download === 1 || download === 0 ? "credit" : "credits"
                    //             }`,
                    //         type: "info",
                    //     })
                    // );
                }
            } catch (err) {
                // console.log("Error", err);
                if (err.response && err.response.status === 400) {
                    dispatch(getUserDetails(err.response.data.detail));
                    // dispatch(
                    // getNotification({
                    //     message:
                    //         "You have run out of credits!! Please subscribe and get more credits.",
                    //     type: "info",
                    // })
                    // );
                } else if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/draft-agreement` });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderCredit(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/draft-agreement` });
            }, 3000);
        }
    };

    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth" // Optionally, you can add smooth scrolling animation
        });
    }
    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)
        const type = document.getElementById("other").value;
        setFileName(type);
        const parties = document.getElementById("partiesName").value;
        const favouredParty = document.getElementById("favourParty").value;
        const governingLaw = document.getElementById("governingLaw").value;
        const jurisdiction = document.getElementById("jurisdiction").value;
        const startDate = document.getElementById("startDate").value;
        const endtDate = document.getElementById("endDate").value;
        const specify = document.getElementById("specify").value;
        const subjectMatter = document.getElementById("subjectMatter").value;
        const defaultProvision = document.getElementById("defaultProvision").value;
        const checkboxSelections = checkBoxString.join(",");
        let mode = "";
        if (value) mode = "Pro"
        else mode = "Basic"
        // response.data.detail.user_credit_summary.message
        // if (state.planDetails.user_credit_summary.message = "User has no remaining downloads") {
        //     dispatch(
        //         getNotification({
        //             message: "You have run out of credits!! Please add top up plan and get more credits to use PRO mode.",
        //             type: "info",
        //         })
        //     );
        //     toggleButton.click()
        //     mode = "Basic"
        // }
        let interval;
        getJoke()
        if (type === "") {
            dispatch(
                getNotification({
                    message: "Please fill the agreement type.",
                    type: "default",
                })
            );
        }
        else if (UserService.isLoggedIn()) {
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
                sendData.append("agreement_type", type);
                sendData.append("parties", parties);
                sendData.append("favoured_party", favouredParty);
                sendData.append("governing_law", governingLaw);
                sendData.append("jurisdiction", jurisdiction);
                sendData.append("start_date", startDate);
                sendData.append("end_date", endtDate);
                sendData.append("term_period", specify);
                sendData.append("subject_matter", subjectMatter);
                sendData.append("default_agreement_settings", defaultProvision);
                sendData.append("checkbox_selections", checkboxSelections);
                sendData.append("api_type_alias", mode);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/create_agreement`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                interval = setInterval(getJoke, 10000);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    clearInterval(interval);
                    const text = getResponse.data.agreement_text;
                    setCreatAgreement(text);
                    // getPlanDetails("noRefresh");
                    // console.log("Success");
                }
            } catch (err) {
                clearInterval(interval);
                setHandleSubmit(false);
                setLoaderAgreement(false);
                console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/draft-agreement` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Sorry, our network is down with too many requests. Please try in sometime!",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement(false);
        }
        else {
            // const unauthData = JSON.parse(localStorage.getItem("UnAuthkey"))
            // try {
            //     setLoaderAgreement(true);
            //     const FormData = require("form-data");
            //     const sendData = new FormData();
            //     sendData.append("keycloak_user_id", unauthData.id);
            //     sendData.append("ip_address", unauthData.ip.ip_address);
            //     sendData.append("user_agent", unauthData.ip.user_agent);
            //     sendData.append("file_name", renameString);
            //     sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
            //     sendData.append("agreement_type", type);
            //     sendData.append("parties", parties);
            //     sendData.append("favoured_party", favouredParty);
            //     sendData.append("governing_law", governingLaw);
            //     sendData.append("jurisdiction", jurisdiction);
            //     sendData.append("start_date", startDate);
            //     sendData.append("end_date", endtDate);
            //     sendData.append("term_period", specify);
            //     sendData.append("subject_matter", subjectMatter);
            //     sendData.append("default_agreement_settings", defaultProvision);
            //     sendData.append("checkbox_selections", checkboxSelections);
            //     sendData.append("api_type_alias", "Basic");
            //     const config = {
            //         method: "post",
            //         url: `${REACT_APP_CREATE_AGREEMENT_API}/create__agreement`,
            //         headers: {
            //             Accept: "application/json",
            //             "Content-Type": "application/json",
            //             "access_token": UserService.getToken()
            //         },
            //         data: sendData,
            //     };
            //     setHandleSubmit(true);
            //     const time = new Date()
            //     // if (getIp() && get)
            //     if (unauthData.value) {
            //         const getResponse = await axios(config);
            //         if (getResponse.status === 200) {
            //             const text = getResponse.data.agreement_text;
            //             setCreatAgreement(text);
            //             unauthData.track.draftAgreementAPi.push(time)
            //             unauthData.value -= 1
            //             localStorage.setItem('UnAuthkey', JSON.stringify(unauthData));
            //             // dispatch(
            //             //     getNotification({
            //             //         message: `You have ${unauthData.value} ${unauthData.value === 1 || unauthData.value === 0 ? "credit" : "credits"} left today`,
            //             //         type: "info",
            //             //     })
            //             // );
            //         }
            //     }
            //     else {
            //         setHandleSubmit(false);
            //         dispatch(
            //             getNotification({
            //                 message: "You have exceeded the daily limit of 15 credits. Please try again tomorrow or register for free account and subscribe to our free plan.",
            //                 type: "default",
            //             })
            //         );
            //         setTimeout(() => window.open("/sign-up", '_blank'), 3500)
            //     }
            // } catch (err) {
            //     setHandleSubmit(false);
            //     setLoaderAgreement(false);
            //     console.log("Error", err);
            //     dispatch(
            //         getNotification({
            //             message: "Sorry, our network is down with too many requests. Please try in sometime!",
            //             type: "default",
            //         })
            //     );
            // }
            // setLoaderAgreement(false);
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}` });
            }, 3000);
        }
        setTimeout(() => preventFromCopyPaste(), 100);
        // getFormDataAfterLoading(); //for getting all field data after creation of Agreement
    };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year} - ${day} - ${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${fileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                if (err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/draft-agreement` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/draft-agreement` });
            }, 3000);
        }
        setTimeout(() => preventFromCopyPaste(), 10);
    };

    const checkBoxStringMaker = (event, name) => {
        if (event.target.checked === true) {
            checkBoxString.push(name);
            setCheckBoxString([...checkBoxString]);
        } else {
            checkBoxString.pop(name);
            setCheckBoxString([...checkBoxString]);
        }
    };
    const refreshForm = () => {
        setOther(true);
        document.getElementById("other").value = ""
        document.getElementById("partiesName").value = ""
        document.getElementById("favourParty").value = ""
        document.getElementById("governingLaw").value = ""
        document.getElementById("jurisdiction").value = ""
        document.getElementById("startDate").value = ""
        document.getElementById("endDate").value = ""
        document.getElementById("specify").value = ""
        document.getElementById("subjectMatter").value = ""
        document.getElementById("defaultProvision").value = ""
        document.querySelectorAll(".check").forEach((e) => (e.checked = false));
        setRenameString("New File")
        setCheckBoxString([]);
        let temp = []
        setTagStore(temp)
    };

    const getCurrentMonthYear = () => {
        const date = new Date();
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getFullYear();
        return `${month} ${year}`;
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const handleConfirm = () => {
        setHandleMode(false)
        sendData()
        setPromt(false)
    };
    const handleProConfirm = () => {
        setHandleMode(true)
        sendData(true)
        setPromt(false)
    }

    const handleCancel = () => {
        setPromt(false)
    };


    const getJoke = async () => {
        try {

            const response = await axios.get("https://official-joke-api.appspot.com/random_joke")
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline
            })
        }
        catch (err) {
            console.log("error", err);
        }
    }

    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="xl:grid xl:grid-cols-4 xl:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                                    <div className={handleSubmit ? "d-none" : "row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                        <div className='col-sm'>
                                            <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span>
                                            <h4 className='text-center text-primary'>Make any Contract or Agreement</h4>
                                        </div>
                                        <div className='col-sm items-center float-end'>
                                            <Link
                                                to="/app"
                                                spiketip-title="Reset Form" spiketip-pos="bottom"

                                                className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                &#8592; Back
                                            </Link>
                                            <button
                                                spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    refreshForm();
                                                }}
                                                className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-redo mr-2"></i>
                                                Reset form
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                                    <div className="row menu-bar mt-1 container-fluid">
                                        <div className={handleSubmit ? "" : `col-sm px-2`}>
                                            <button className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"}`} >
                                                <input
                                                    id="input-file-name"
                                                    className={rename ? "float-start w-4/6" : "d-none"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") setRename(false);
                                                    }}
                                                    onChange={(e) => {
                                                        setRenameString(e.target.value);
                                                    }}
                                                    defaultValue={renameString}
                                                    type="text"
                                                    style={{
                                                        // width: "150px",
                                                        height: "30px",
                                                        color: "black",
                                                    }}
                                                />
                                                <span
                                                    onClick={() => {
                                                        if (rename) setRename(false);
                                                        else setRename(true);
                                                    }}
                                                    id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                    className={rename ? "d-none" : "float-start text-primary"}>
                                                    {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                                </span>
                                                <span className="ml-2 float-end">
                                                    |
                                                    <i
                                                        onClick={() => {
                                                            if (rename) setRename(false);
                                                            else setRename(true);
                                                        }}
                                                        className={
                                                            rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                        }
                                                    >
                                                    </i></span>

                                                {/* <i className="fas fa-times"></i> */}
                                            </button>
                                        </div>
                                        <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        getTagButtonFromArray(e.target.value)
                                                    }
                                                }}
                                                placeholder="Add tag here"
                                                className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                                style={{
                                                    height: "35px",
                                                    // fontSize: handleSubmit ? "14px" : "",
                                                    width: "110px",
                                                    border: "2px solid #007BFF",
                                                    textAlign: "left",
                                                }}
                                                type="text"
                                            />
                                            <button
                                                spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                                onClick={() => getTagButtonFromArray()}
                                                id="add-tag"
                                                className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                            >
                                                <i className="fas fa-plus plus-position"></i>
                                            </button>

                                            <span className="scrollable-span mx-1 mt-1 pb-1 w-4/6">
                                                {tagStore.length > 0 &&

                                                    tagStore.map((ele, ind) => (
                                                        <button
                                                            style={{ borderRadius: "9999px" }}
                                                            key={ind}
                                                            className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                            onMouseEnter={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.remove("d-none")
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.add("d-none")
                                                            }}
                                                        >
                                                            {ele}
                                                            <i
                                                                // style={{ display: "none" }}
                                                                onClick={() => { removeTagFromArray(ele) }}
                                                                className="fas fa-window-close ml-1 d-none"></i>
                                                        </button>
                                                    ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white container-fluid">
                                        <div className="col-span-6 sm:col-span-3 mt-0 mt-2">
                                            <label
                                                htmlFor="typeOfContract"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Type Of Contract :{" "} <span className="text-danger">*</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-span-6 sm:col-span-3 selectMobileRemover1">
                                                    <input
                                                        type="text"
                                                        placeholder="Fill Your Agreement Type"
                                                        id="other"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {firstPart.map((e, i) => (
                                            <div key={i} className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor={e.id}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {e.name} (optional)
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder={e.placeholder}
                                                    name={e.id}
                                                    id={e.id}
                                                    autoComplete="given-name"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        ))}
                                        {/* {d-none- not user below code} */}
                                        <div className="col-span-6 sm:col-span-3 d-none">
                                            <label
                                                htmlFor="startDate"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {/* Term : Mention start date of the contract (if applicable) */}
                                                Period of the contract or term: feel free to mention
                                                the start and end date of the contract. (optional)
                                            </label>
                                            <div className="d-flex">
                                                <input
                                                    type="text"
                                                    placeholder="Start Date"
                                                    name="startDate"
                                                    id="startDate"
                                                    autoComplete="given-date"
                                                    className="mt-1 mx-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="End Date"
                                                    name="endDate"
                                                    id="endDate"
                                                    autoComplete="given-date"
                                                    className="mt-1 mx-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                                        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                                                            Period of the contract or term: feel free to mention the start and end date of the contract
                                                            <span className='red'> *</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder=''
                                                            name="endDate"
                                                            id="endDate"
                                                            autoComplete="given-name"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        />
                                                    </div> */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="specify"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Specify the term of the contract. (optional)
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="in years, months, days"
                                                name="specify"
                                                id="specify"
                                                autoComplete="given-name"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        {secondPart.map((e, i) => (
                                            <div key={i} className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor={e.id}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {e.name} (optional)
                                                </label>
                                                <div className="mt-1">
                                                    <textarea
                                                        id={e.id}
                                                        name={e.id}
                                                        rows={3}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder={e.placeholder}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-span-6 sm:col-span-3">
                                            <fieldset>
                                                <legend className="sr-only">By Email</legend>
                                                <div
                                                    className="text-sm font-semibold text-gray-900"
                                                    aria-hidden="true"
                                                >
                                                    Choose specific standard legal terms you might want
                                                    included: Only recommended for legal
                                                    professionals/advanced users. (optional)
                                                </div>
                                                {other ? (
                                                    <div className="row p-1">
                                                        {checkBoxArrayOfOther.map((ele, ind) => (
                                                            <div key={ind} className="col-sm">
                                                                {ele.map((e, i) => (
                                                                    <div key={i * 100} className="mt-1">
                                                                        <div className="flex items-start">
                                                                            <div className="flex h-5 items-center">
                                                                                <input
                                                                                    id={String(i) + String(ind)}
                                                                                    onChange={(event) => {
                                                                                        checkBoxStringMaker(event, e);
                                                                                    }}
                                                                                    type="checkbox"
                                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 check"
                                                                                />
                                                                            </div>
                                                                            <div className="ml-3 text-sm">
                                                                                <label
                                                                                    htmlFor={String(i) + String(ind)}
                                                                                    className="font-medium text-gray-700 cursor-pointer checkbox-class-other"
                                                                                >
                                                                                    {e}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    (clause
                                                        ? checkBoxArrayOfLease
                                                        : checkBoxArrayOfService
                                                    ).map((e, i) => (
                                                        <div key={i} className="mt-1">
                                                            <div className="flex items-start">
                                                                <div className="flex h-5 items-center">
                                                                    <input
                                                                        id={i}
                                                                        onChange={(event) => {
                                                                            checkBoxStringMaker(event, e);
                                                                        }}
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 check"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm">
                                                                    <label
                                                                        htmlFor={i}
                                                                        className="font-medium text-gray-700 cursor-pointer checkbox-class-nonother"
                                                                    >
                                                                        {e}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </fieldset>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3 text-center mx-2">
                                            {promt && <Popup
                                                onConfirm={handleConfirm}
                                                onProConfirm={handleProConfirm}
                                                onCancel={handleCancel}
                                                active={true}
                                            />}
                                            <Link
                                                to="/app"
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-outline-dark mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => { setPromt(true) }}
                                            >
                                                Back
                                            </Link>
                                            <button
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-primary mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                onClick={() => { setPromt(true) }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title={
                                            handleMode ?
                                                "It can take up to 90 seconds for TipsyTom to script in Owl Mode."
                                                : "It can take up to 30 seconds for TipsyTom to script in Falcon Mode."
                                        }
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                <br />
                                <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                    Mark Twain
                                </figcaption> */}

                                    <h4 className="font-italic text-center mt-5"> {joke.setup}</h4>
                                    <h5 className="font-italic text-center mt-2"> {joke.punchline}</h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar">
                                            <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                <div className='col-sm'>
                                                    <h5 className='text-center text-primary'>Make any Contract or Agreement</h5>
                                                    <p className='p-0 mx-4 my-0 text-primary'>{handleMode ? "Owl" : "Falcon"}</p>
                                                </div>
                                                <div className='col-sm text-end'>
                                                    <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                    <button
                                                        spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) {
                                                                setContentState(!contentState);
                                                                setTimeout(() => preventFromCopyPaste(), 10);
                                                                if (contentState) {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = true;
                                                                    document
                                                                        .getElementById("download")
                                                                        .setAttribute("disabled", true);
                                                                } else {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = false;
                                                                    document
                                                                        .getElementById("download")
                                                                        .removeAttribute("disabled");
                                                                }
                                                            }
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/draft-agreement" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to edit the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i
                                                            id="icon"
                                                            className={
                                                                contentState ? "fas fa-edit" : "fas fa-save"
                                                            }
                                                        ></i>
                                                    </button>
                                                    <button
                                                        spiketip-title="Download Agreement" spiketip-pos="left"
                                                        id="download"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) download();
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/draft-agreement" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to download the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: "1129px",
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement}
                                                <br />
                                            </div>
                                            <div className='text-center'>
                                                <button
                                                    spiketip-title="Download Agreement" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/draft-agreement" })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <FooterOthers />
                    </div>
                </div>
            )
            }
        </div>
    );
};

export default DraftAgreement;

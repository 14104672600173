import React from 'react'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

function PaymentFailed() {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <ExclamationCircleOutlined style={{ fontSize: "64px", color: "#f5222d" }} />
            <h1 style={{ margin: "20px 0" }}>Payment Failed</h1>
            <p style={{ marginBottom: "20px" }}>
                We're sorry, but your payment was unsuccessful. Please try again or contact customer support.
            </p>
            <Link className="btn btn-danger" to="/app">
                Back to Home
            </Link>
        </div>
    )
}

export default PaymentFailed
export const tabItem = [
    // {
    //     name: "Caveat Petition",
    //     description1: "AI powered Scripter to prepare high quality Caveat Petitions for multiple Petitioners and Courts, in less than 15 minutes. Designed for use in Indian courts.",
    //     to: "/app/caveat-petition",
    //     disabled: true,
    //     indiaflag: true,
    //     recentlyAdded: true
    // },
    // {
    //     name: "Shareholders' Resolution",
    //     description1: "AI powered Scripter to prepare high quality Shareholders' Resolution, in less than 15 minutes. Designed for use in Indian jurisdiction.",
    //     to: "/app/shareholders-resoultion",
    //     disabled: true,
    //     indiaflag: true,
    //     recentlyAdded: true
    // },
    {
        heading: true,
        name: "Patent Applications – Detailed and nuanced specifications in a matter of minutes",
    },
    {
        name: "Patent Applications",
        description1:
            "Detailed and nuanced specifications in a matter of minutes",
        description2: " ",
        buttonName: "Script an application now!",
        to: "https://mypatentwriter.com/",
        disabled: false,
        paid: true,
        redirection: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        // indiaflag: true,
        recentlyAdded: true
    },
    {
        heading: true,
        name: "Other Applications – Complex facts and analysis taken care of"
    },
    {
        name: "Bail Application",
        description1:
            "AI powered Scripter to prepare high quality and accurate bail application in less than 15 minutes. Designed for use in Indian courts.",
        description2: " ",
        buttonName: "Script an application now!",
        to: "https://draftbailapplication.com/",
        disabled: false,
        paid: true,
        redirection: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Anticipatory Bail Application",
        description1:
        "AI powered Scripter to prepare high quality and accurate anticipatory bail application in less than 15 minutes. Designed for use in Indian courts.",
        description2: "",
        buttonName: "Script an application now! ",
        to: "https://draftbailapplication.com/",
        disabled: false,
        paid: true,
        redirection: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Exemption Personal Appearance (Cr.PC)",
        description1:
            "Quickly and accurately draft an application under S. 205 of Cr.PC for exemption from personal appearance of accused in a criminal proceeding. ",
        description2: " ",
        buttonName: "Script an application now!",
        to: "/app/exemption-application-from-pa",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    // {
    //     name: "Application for Interim Relief - Arbitration (Section 9 of A&C Act)",
    //     description1: "AI powered Scripter to prepare high quality and accurate S. 9 application for interim relief pending arbitration, in less than 15 minutes. Designed for use in Indian courts.",
    //     to: "/app/shareholders-resoultion",
    //     disabled: true,
    //     indiaflag: true,
    //     recentlyAdded: true,
    // },
    {
        heading: true,
        name: "Common Agreements – Standard terms and your variations aligned"
    },
    {
        name: "Non-Disclosure Agreement (NDA)",
        description1:
            "A non-disclosure agreement is an agreement that restricts disclosure of confidential information that is shared between parties. ",
        buttonName: "Script an NDA! ",
        to: "/app/non-disclosure-agreement",
        margin1: "30px",
        margin3: "40px",
    },
    {
        name: "Employment Agreement or Offer Letter",
        description1:
            "Fill in only what is required and generate a draft of an employment contract or offer letter in seconds.   ",
        buttonName: "Make an Offer Letter!",
        to: "/app/employment-agreement",
        margin1: "30px",
        margin3: "60px",

    },
    {
        name: "Service Agreement",
        description1:
            "Customise your own service agreement by scripting in the particulars of the services being bought and sold, and the terms that cover the arrangement. ",
        buttonName: "Script a service agreement!",
        to: "/app/service-agreement",
        disabled: true,
        paid: true,
        margin1: "30px",
        margin3: "40px",
    },
    {
        name: "Supply Agreement",
        description1:
            "Customise your own supply agreement by scripting in the particulars of the goods being bought and sold, and the terms that govern the sale. ",
        buttonName: "Script a supply contract!",
        to: "/app/supply-agreement",
        disabled: true,
        paid: true,
        margin1: "30px",
        margin3: "50px",
    },
    {
        name: "Lease Agreement",
        description1: "Lease Agreements vary widely across geographies and depending on what is being leased and to whom.",
        description2: "Get over the hoops of figuring out things by using the power of language models. ",
        buttonName: "Script a lease!",
        to: "/app/lease-agreement",
        disabled: true,
        paid: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
    },
    {
        name: "Legal Notice or Communication",
        description1:
            "Prepare a formal letter to someone either on behalf of a client or yourself. Useful where the letter relates to a legal notice, claim, warning or complaint.",
        description3:
            "Read how you can match a top American law firm by using this tool.",
        buttonName: "Script the letter!",
        to: "/app/legal-notice",
        disabled: true,
        paid: true,
        margin1: "10px",
        margin2: "18px",
        margin3: "15px",
    },
    {
        heading: true,
        name: "Documents in specialized fields – Reflect expertise and industry knowledge"
    },
    {
        name: "IP License Agreement",
        description1: "Prepare an Intellectual Property License Agreement.",
        description2: "If you (or your client) are the author or owner of intellectual property (music, software, art, drawings, video etc.) and wish to license the work, this is where you script the agreement.",
        buttonName: "Script an IP License! ",
        to: "/app/ip-licensing-agreement",
        disabled: true,
        margin1: "5px",
        margin2: "5px",
        margin3: "5px",
    },
    {
        name: "IP Assignment Agreement",
        description1: "Prepare an Intellectual Property Assignment Agreement.",
        description2: "If you (or your client) are the author or owner of intellectual property (music, software, art, drawings, video etc.) and wish to sell the work, this is where you script the agreement.  ",
        buttonName: "Script an IP Assignment! ",
        to: "/app/ip-assignment-agreement",
        disabled: true,
        margin1: "5px",
        margin2: "5px",
        margin3: "3px",
    },
    {
        heading: true,
        name: "Specialised Drafting Assistants"
    },
    {
        name: "Make any Contract or Agreement",
        description1:
            "Works for any type of agreement or contract! Use this to script contracts that are one-off, or for which you don’t have a template. ",
        buttonName: "Script any contract! ",
        to: "/app/draft-agreement",
        disabled: true,
        paid: true,
        margin1: "35px",
        margin3: "50px",
    },
    {
        name: "Power Editor (Beta)",
        description1:
            "Want to modify or alter a contract, clause or text? Use this tool to get your legal editing done.",
        description2: "Have your text ready, and review the results carefully! ",
        buttonName: "Edit my clauses! ",
        to: "/app/power-editor",
        disabled: true,
        paid: true,
        margin1: "30px",
        margin2: "30px",
        margin3: "15px",
    },
];

// {
//     name: "Document Library",
//     description1:
//         "Precedents and templates form the base for all drafting work.",
//     description2:
//         "All your past scripts and generated drafts in one place, easy to search, tag, retrieve and regenerate.",
//     buttonName: "Sign-up or log in to access",
//     to: "/app/library",
//     disabled: true,
//     margin1: "15px",
//     margin2: "10px",
//     margin3: "20px",
// },

// {
//     name: "Customised Templates",
//     description1:
//         "Do you have your own templates you would like to work with, safely and securely? Does your organisation have formats and standards that must be respected?",
//     description2:
//         "Use Tom to work on them exclusively for you, catering to your specific needs.",
//     description3: "Tom will help curate your templates and generate transaction specific documents in no time.",
//     buttonName: "Here’s how.",
//     to: "/app/customised-template",
//     margin1: "2px",
//     margin2: "-5px",
//     margin3: "-5px",
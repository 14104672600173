export const data1 = [
    {
      name: "Name the parties to the Contract (optional)",
      placeholder:"Name the parties along with roles, separated by commas. For example - John Smith (Assignor), ABC Limited (Assignee).",
      id: "parties",
    },
    {
      name: " Description of Intellectual Property: (optional)",
      placeholder:"What is the Intellectual Property being assigned? For example: A music album called “Rage” by the band Quick Motion.",
           id: "ip_description",
    },
    {
      name: "Territory: (optional)",
      placeholder:"Mention if there are any territorial restrictions for the assignment. Leave this blank for standard provisions.",
            id: "territory",
    },
    {
      name: "Payments and fees: (optional)",
      placeholder:"Mention the consideration to be paid for assigning the Intellectual Property. For example: USD 50,000. ",
            id: "payment_fees",
    },

    {
      name: "Taxes and costs : (optional)",
      placeholder:"Mention the Party that will bear the taxes and costs. Leave this blank for standard provisions. ",   
         id: "taxes_borne_by",
    },
    {
      name: "Other terms : (optional)",
      placeholder:"Mention if there are any other specific terms to be included in the Contract.",   
         id: "other_terms",
    },
    {
      name: " Governing Law: (optional)",
      placeholder:
        "Mention the name of your country or state whose laws will apply. Examples: UAE. California.  ",
          id: "governing_law",
    },
    {
      name: "Jurisdiction: (optional)",
      placeholder:"Name your city whose courts will resolve disputes (Example: Tokyo. Hong Kong. London. San Francisco.)",
          id:  "jurisdiction",
    },


  ];
  
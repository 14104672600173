export const firstPart = [
    {
        name: "Name the parties to the contract : (optional) ",
        placeholder: "Include as many as you want (including roles of each Party), separated by commas. For example - John Smith (as Lessor), ABC Limited (as Lessee)",
        id: "parties",
    },
    {
        name: "Mention the party in whose favour the contract should be drafted : (optional) ",
        placeholder: "Leave blank if you would like a balanced contract",
        id: "favouring_party",
    },
    {
        name: "Purpose of the Lease : (optional) ",
        placeholder: "Mention if the lease is being taken for commercial or residential purposes",
        id: "lease_purpose",
    },
    {
        name: "Specify the term of the contract : (optional)",
        placeholder: "in years, months, days",
        id: "contract_term",
    },
    // {
    //     name: "Rent and Deposit : ",
    //     placeholder: "Specify the deposit and the rent. Also describe the due dates for rent, deductions from deposit, mode of payment, and return of deposit.",
    //     id: "governingLaw",
    // },
    // {
    //     name: "Jurisdiction : ",
    //     placeholder: "Mention the courts which will judge disputes related to the contract",
    //     id: "jurisdiction",
    // },
]

export const secondPart = [
    {
        name: "Rent and Deposit (optional)",
        placeholder: "Specify the deposit and the rent. Also describe the due dates for rent, deductions from deposit, mode of payment, and return of deposit.",
        id: "rent_and_deposit",
    },
    {
        name: "Lessee’s right : (optional)",
        placeholder: "Describe rights given to lessee under the contract, such as, exclusive possession, no interference, right to alter, affix structures etc.",
        id: "lessees_right",
    },
    {
        name: "Lessee’s obligations : (optional)",
        placeholder: "Describe Lessee’s obligations under the contract such as residential use, no nuisance, use of property etc.",
        id: "lessees_obligations",
    },
    {
        name: "Lessor’s rights : (optional)",
        placeholder: "Describe rights of lessors such as inspection rights, visitation of potential lessees during notice period etc.",
        id: "lessors_rights",
    },
    {
        name: "Lessor’s obligations : (optional)",
        placeholder: "Describe lessor’s obligations such as payment of specific taxes, charges and duties, such as to repair, maintain etc.  ",
        id: "lessors_obligations",
    },
    {
        name: "Consequences of default : (optional)",
        placeholder: "Mention implications of the Parties not complying with their obligations/ responsibilities under the contract, such as a deduction from the deposit or penalties for late payment.",
        id: "default_consequences",
    },
    {
        name: "Governing Law (optional)",
        placeholder: "Which country (and if applicable, state, territory or city) is the lease agreement being entered in?",
        id: "governing_law",
    },
    {
        name: "Jurisdiction (optional)",
        placeholder: "Mention the courts which will judge disputes related to the contract",
        id: "jurisdiction",
    },
    {
        name: "Dispute Resolution : (advanced) (optional)",
        placeholder: "The draft will default to the jurisdiction clause. Add additional dispute resolution measures you would like, if any, such as arbitration or mediation.",
        id: "dispute_resolution",
    },
    {
        name: "Termination (optional)",
        placeholder: "Describe the mechanism for termination such as notice period requirements or termination without notice etc.",
        id: "termination_clause",
    },
]
import React from 'react'
import { Link } from 'react-router-dom';
import backgroundImageNav from "../../assets/backgroundImageNav.png"
import backgroundImageNav2 from "../../assets/backgroundImageNav2.png"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import logo from "../../assets/Logo-1.png"

const FooterLanding = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <div className='relative bg-[#4153D2] w-full' style={{ padding: "5vw 8vw 2vw 8vw", backgroundImage: `url(${backgroundImageNav})`, backgroundRepeat: "no-repeat", }}>
            <img loading="lazy" className='absolute right-0 bottom-0' src={backgroundImageNav2} alt="Second Image" />
            <div>
                <div className="row">
                    <div className="col-sm-9">
                        <h1 className='text-white' style={{ fontSize: "40px" }}>Scripter App is free!</h1>
                        <h4 className='text-white'>No credit card required. Your one-stop generative AI platform for legal work.</h4>
                    </div>
                    <div className="col-sm-3">
                        <button onClick={() => navigate("/app")} className='fw-bold text-primary bg-white hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-2xl transition duration-300 ease-in-out' style={{ fontSize: "25px" }}>Try for free</button>
                    </div>
                </div>
                <br /><br /><br />
            </div>
            <hr className='bg-white' />
            <div>
                <div className='row'>
                    <div className="col-sm">
                        <span className='d-flex'>
                            <img style={{ width: "150px" }} src={logo} alt="logo" />
                            <span className='ml-2 mr-5 text-white' style={{ opacity: "0.5" }}>©2023 All rights @Lipi Labs. Doing AI right.</span>
                        </span>
                    </div>
                    <div className="col-sm d-flex justify-content-around">
                        <span onClick={() => navigate("/app/contact-us", { state: { data: location.pathname } })} className='cursor-pointer text-white'>
                            <img loading="lazy" width="20" height="20" src="https://img.icons8.com/fluency/20/mail--v1.png" alt="mail--v1" />
                            <span className='position-relative -top-5 left-6'>
                                Contact Us : help@scripters.app
                            </span>
                        </span>
                        <span className='d-flex justify-content-center -mt-2'>
                            <Link style={{ color: "#000000" }} className="nav-link text-white" to="/privacy-policy">
                                <span className='underline-nav'>Privacy Policy</span>
                            </Link>
                            <Link style={{ color: "#000000" }} className="nav-link text-white" to="/terms-of-service">
                                <span className='underline-nav'>Terms of service</span>
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterLanding
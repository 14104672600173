export const data1 = [
  {
    name: "Name the parties to the Contract (optional)",
    placeholder:
      "Name the parties along with roles, separated by commas. For example -ABC Limited (Purchaser), XYZ Limited (Supplier)",
    id: "parties",
  },
  {
    name: "Describe the goods (optional)",
    placeholder:
      "Describe the products to be supplied under the agreement, their quality standard, certifications and any other details.",
    id: "goods_description",
  },
  {
    name: " Warranty (optional)",
    placeholder:
      "Any warranties being provided for the goods? For example, that it is fit for purpose, or that it will be defect free for a specified period.",

    id: "warranty",
  },
  {
    name: "Pricing and payments (optional)",
    placeholder:
      "What is the price for the goods, and how will the payments be made?  ",
    id: "pricing_payments",
  },
  {
    name: " Any other terms : (optional)",
    placeholder:
      "Add any other terms that you think might be relevant. Such as consequences of non-delivery, goods being non-satisfactory, any special conditions for acceptance, etc.  ",
    id: "other_terms",
  },
  {
    name: "  Governing Law: (optional)",
    placeholder:
      "Mention the name of your country or state whose laws will apply. Examples: UAE. California.  ",
    id: "governing_law",
  },
  {
    name: " Jurisdiction: (optional)    ",
    placeholder:
      "Name your city whose courts will resolve disputes (Example: Tokyo. Hong Kong. London. San Francisco.) ",
    id: "jurisdiction",
  },
];
export const checkBoxArray = [
 []

]



export const data1 = [
    {
        name: "Place your template text here :",
        placeholder: "The template text can be a template of the whole document or the parts that you wish to use in a document. Example 1: You can include only the extract/ clause as the template text. Example 2: You can provide an entire draft. Give instructions accordingly. Current word limit: 1000 words.",
        id: "template_text",
    },
    {
        name: "What would you like Tom to do with the template text?",
        placeholder: "You can state your instructions separated by full stops. It is best to provide sufficient context and the changes to be made to the text. The more detailed your instructions, the more accurate the revision. Your drafts and instructions are stored in the document library for future reference.",
        id: "to_do_with_template_text",
    },
]

export const firstPart = [
    {
        name: "Title : (optional)",
        placeholder: "Enter Subject of the letter:",
        id: "title",
    },
    {
        name: "Reference : (optional)",
        placeholder: "Add Reference",
        id: "reference",
    },
    {
        name: "Date : (optional)",
        placeholder: "Insert date",
        id: "date",
        type:"date"
    },
    {
        name: "Client : (optional)",
        placeholder: "Enter client details",
        id: "client",
    },
    {
        name: "Recipient : (optional)",
        placeholder: "Enter recipient",
        id: "recipient",
    },
]

export const secondPart = [
    {
        name: "Subject : (optional)",
        placeholder:"Enter your subject line",
        id: "subject",
    },
    {
        name: "Facts : (optional)",
        placeholder:"Enter factual background",
        id: "facts",
    },
    {
        name: "Violation : (optional)",
        placeholder:"Describe the contractual or legal violations",
        id: "violation",
    },
    {
        name: "Legal Basis : (optional)",
        placeholder:"Any legal arguments that you wish to make, or the legal basis for the action",
        id: "legalBasis",
    },
    {
        name: "Remedy : (optional)",
        placeholder:"Describe the remedies you seek, such as money, cease or desist, vacate possession etc",
        id: "remedy",
    },
    {
        name: "Time Limits : (optional)",
        placeholder:"Time limits that are relevant or must be adhered to",
        id: "timeLimits",
    },
    {
        name: "Consequences : (optional)",
        placeholder:"Consequences of non-compliance. Legal or other consequences that will follow if the notice is not complied with",
        id: "consequences",
    },
    {
        name: "Jurisdiction : (optional)",
        placeholder:"Where the sender of the notice is situated, jurisdiction or the laws/local style of drafting that will apply.",
        id: "jurisdiction",
    },
    {
        name: "List : (optional)",
        placeholder:"List of any supporting documents",
        id: "list",
    },
]
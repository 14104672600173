import React, { useEffect, useState } from 'react';
import '../css/progressbar.css'; // Import your CSS file for styling
import gearbackground from "../../assets/gearbackground.png"
import gear1 from "../../assets/gear1.png"
import gear2 from "../../assets/gear2.png"
import gear3 from "../../assets/gear3.png"

const CustomLoader = ({ mode, }) => {
  const [progress, setProgress] = useState(0);
  let totalTime = 0;
  useEffect(() => {
    getDetails()

  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      });
    }, (totalTime * 1000) / 100);

    return () => clearInterval(interval);
  }, [totalTime]);

  const getDetails = () => {
    if (mode === "Falcon") totalTime = 30
    else if (mode === "Owl") totalTime = 60
    else totalTime = 30 //new 3 tabs
  }


  return (
    <section>
      <div className='relative'>
        <img loading="lazy" className='mx-auto' src={gearbackground} alt="Gear Background" />
        <img loading="lazy" className='gear1' src={gear1} alt="Gear 1" />
        <img loading="lazy" className='gear2' src={gear2} alt="Gear 2" />
        <img loading="lazy" className='gear3' style={{ top: "12rem", left: "12rem", transform: 'rotate(360deg)' }} src={gear3} alt="Gear 3" />
        <h3 className='' style={{ top: "12rem", }} >It can take up to 30 seconds for TipsyTom to script in Falcon mode</h3>
        <h3 className='text-primary absolute text-center'>10 seconds left</h3>
        <div className="progress-bar-container absolute" >
          <div className="progress-bar">
            {/* <div
            className="progress"
            style={{ width: `${progress}%` }}
          ></div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomLoader;
import React, { useState } from 'react';
import "../css/popup.css"

const NoteForUser = ({ note, onConfirm, link, contentViolation }) => {
    const [showPopup, setShowPopup] = useState(true);

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onConfirm();
    };
    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary" role="alert">
                    <div style={{ width: "700px" }} className="popup-container p-5 position-relative">
                        <i onClick={handleCancel} className='far fa-window-close fa-2x position-absolute top-2 right-5 cursor-pointer'></i>
                        <p className="popup-message my-2 text-secondary float-start"><b className={contentViolation ? "d-none" : ""}>Note for users : </b>{note}</p>
                        <p className={link ? "m-0" : "d-none"}>Knowledge Partner: <a className='underline' target="_blank" href="https://www.linkedin.com/in/abhinav-hansaraman-270b40103/">Abhinav Hansaraman</a></p>
                        <div className='text-center mt-2'>
                            <button
                                onClick={handleConfirm}
                                className='btn btn-primary mt-3 rounded-xl'>Proceed</button>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default NoteForUser;
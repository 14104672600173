export const noteforusers =
  "While most of the fields are optional, it is good to fill all relevant fields. Better your text inputs, more detailed and accurate the draft! Feel free to use simple and minimum, 'to the point' inputs, and TipsyTom will do the drafting for you. Don’t worry about the text you put in here, they will remain even after you have generated the document in case you need to change or update anything and re-generate, and also in your Document Library! The tone, style and drafting in this Scripter is designed for use in Indian courts.";


export const formdata = [
  {
    name: "Section 1: Details of Complaint",
    group: [
      {
        name: "Name of the Court:",
        placeholder: "THE COURT OF LEARNED CITY CIVIL AND SESSIONS JUDGE",
        id: "court_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Name of Complainant:",
        placeholder: "Devendra Sharma",
        id: "complainant_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Court Case Number: ",
        placeholder: "CC No. [] of []",
        id: "case_number",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
  {
    name: "Section 2: Details of the Accused",
    group: [
      {
        name: "Name of Primary Accused:",
        placeholder: "Hari Sharma",
        id: "accused_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Primary accused No.:",
        placeholder: "1",
        id: "accused_no",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Whether the accused is a body corporate?",
        // placeholder: "Male/Female",
        // id: "gender_switch",
        // type: "input-select",
        groupDiff: [
          {
            name: "Yes",
            element: "input-radio",
            type: "radio",
            nameAtt: "company_question",
            id: "company_question_yes",
          },
          {
            name: "No",
            defaultSelected: true,
            element: "input-radio",
            type: "radio",
            nameAtt: "company_question",
            id: "company_question_no",
          },
          {
            name: "Name of the accused for whom the exemption from personal appearance is sought:",
            placeholder: "Hari Sharma",
            class: "companyQuestion",
            id: "accused_pa_name",
            type: "text",
            element: "input-text",
            required: true,
            optional: true,
          },
          {
            name: "Accused No :",
            placeholder: "1",
            class: "companyQuestion",
            id: "accused_pa_no",
            type: "text",
            element: "input-text",
            required: true,
            optional: true,
          },
          {
            name: "Relationship of above accused with the primary accused :",
            placeholder: "Director or Authorized Representative",
            class: "companyQuestion",
            id: "accused_relationship",
            type: "text",
            element: "input-text",
            required: true,
            optional: true,
          },
          {
            name: "Gender of primary accused:",
            name2: "Gender of accused:",
            placeholder: "Male/Female",
            id: "gender_switch",
            type: "input-select",
            group: [
              {
                name: "Male",
                element: "input-select",
                nameAtt: "Male/Female",
                id: "MaleGenderComplainant",
                type: "input-select",
              },
              {
                name: "Female",
                element: "input-select",
                type: "input-select",
                nameAtt: "Male/Female",
                id: "FemaleGenderComplainant",
              },
            ],
          },

        ],
      },

      // {
      //   name: "If the accused is a company/ body corporate? Yes/ No:",
      //   placeholder: "Yes",
      //   id: "company_question",
      //   type: "text",
      //   element: "input-text",
      //   required: false,
      //   optional: true,
      // },

    ],
  },
  {
    name: "Section 3: Exemption Sought",
    group: [
      {
        name: "Main reasons for absence of the accused: ",
        placeholder: "Mother’s illness ",
        id: "reasons_absence_1",
        type: "text",
        element: "input-text",
        required: true,
        optional: true,
        wordLimit: true,
      },
      {
        name: "Supporting document (for main reason)",
        placeholder: "Medical certificate of the mother",
        id: "supporting_doc_1",
        type: "text",
        element: "input-text",
        required: true,
        optional: true,
        wordLimit: true,
      },
      {
        name: "Additional reasons, if any:",
        placeholder: "Traveling to Delhi with mother for her medical treatment",
        id: "reasons_absence_2",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Supporting document (for additional reasons)",
        placeholder: "Medical certificate of the mother ",
        id: "supporting_doc_2",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Hearings for which exemption sought:",
        group: [
          {
            name: "Every Hearing",
            element: "input-radio",
            type: "radio",
            nameAtt: "hearingdateexemptions",
            id: "EveryHearing",
          },
          {
            name: "Select Dates:",
            element: "input-radio",
            type: "radio",
            nameAtt: "hearingdateexemptions",
            id: "SelectDates",

          },
          {
            // name: "Other",
            placeholder: "Select the hearing dates for which exemption is seeked",
            element: "dateselect",
            type: "date-select",
            // id: "oth",
            id: "INPUT_EXEMPTIONDATES",
          },
        ],
      },
      {
        name: "Place of hearing: ",
        placeholder: "Pune",
        id: "place_of_hearing",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },

      {
        name: "Is accused place of residence same as place of hearing",
        placeholder: "Yes/No",
        id: "residence_same_as_hearing_place",
        type: "input-select",
        group: [
          {
            name: "No",
            element: "input-select",
            type: "input-select",
            nameAtt: "place of residence same as place of hearing",
            id: "Noplaceofhearing",
          },
          {
            name: "Yes",
            element: "input-select",
            nameAtt: "place of residence same as place of hearing",
            id: "Yesplaceofhearing",
            type: "input-select",
          },

        ],


      },
      {
        name: "Accused place of residence:",
        placeholder: "Raigarh",
        id: "accused_residence_city",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: " Date of exemption application: ",
        placeholder: " 30/10/2023",
        id: "date",
        type: "date",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
  {
    name: "Section 4: Details of Advocate",
    group: [
      {
        name: "Name:",
        placeholder: "Ismail Khan",
        id: "advocate_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "City:",
        placeholder: "Pune",
        id: "advocate_city",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
];

export const fieldNames = [
  "complainant_name",
  "court_name",
  "accused_name",
  "case_number",
  "accused_no",
  "additional_grounds",
  "accused_no",
  "gender_switch",
  // "company_question",
  "residence_same_as_hearing_place",
  "accused_residence_city",
  "reasons_absence_1",
  "reasons_absence_2",
  "supporting_doc_1",
  "supporting_doc_2",
  "place_of_hearing",
  "date",
  "advocate_name",
  "advocate_city",
  "accused_pa_name",
  "accused_pa_no",
  "accused_relationship",
];


export const data1 = [
    {
        name: "Name the parties to the contract : (optional)",
        placeholder: "Include the names of parties, separated by commas. For example - John Smith, ABC Limited.",
        id: "parties",
    },
    {
        name: "Details of sensitive information or content being disclosed to the other party (optional)",
        placeholder: "If left blank, standard definition of ‘confidential information’ will be included.",
        id: "sensitiveContent",
    },
    {
        name: "Are there any exceptions to this rule? Specify information that should not be classified as confidential. (optional)",
        placeholder: "If left blank, the draft will include standard exceptions.",
        id: "ruleExceptions",
    },
    {
        name: "Provide a list of persons to whom confidential information may be disclosed by the receiving party. (optional)",
        placeholder: "If left blank, the draft will include disclosure to employees and professional advisors.",
        id: "personsAllowed",
    },
    {
        name: "What is the period during which the non-disclosure obligation should operate? (optional)",
        placeholder: "Describe in days, months, years or perpetuity.",
        id: "termPeriod",
    },
    {
        name: "Governing Law (optional)",
        placeholder: "Mention the name of your country or state whose laws will apply. Examples: UAE. California.",
        id: "governingLaw",
    },
    {
        name: "Jurisdiction (optional)",
        placeholder: "Name your city whose courts will resolve disputes (Example: Tokyo. Hong Kong. London)",
        id: "jurisdiction",
    },
]


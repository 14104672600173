export const checkBoxArrayOfLease =
    [
        'Term',
        "Damages",
        'Consequences of Termination',
        'Insurance',
        'Assignment',
        'Change in Law',
        'Indemnity',
        'Subletting',
        'Breach',
        'Dispute resolution',
        'Miscellaneous'
    ]


export const checkBoxArrayOfService =
    [
        'Scope of work',
        'Term',
        'Termination',
        'Obligations',
        'Representations and warranties',
        'Payment',
        'Intellectual property',
        'Indemnification',
        'Limitation of liability',
        'Confidentiality',
        'Dispute resolution',
        'Force majeure',
        'Notices',
        'Waiver',
        'Separability',
        'Counterparts'
    ]
export const checkBoxArrayOfOther = [
    [
        'Term',
        'Rent',
        'Deposit',
        'Tenant’s obligation',
        'No alteration',
        'Landlord’s right',
    ],
    [
        'Indemnity',
        'Subletting',
        'Breach',
        'Dispute resolution',
        'Miscellaneous',
        'Scope of work',
    ],
    [
        'Termination',
        'Obligations',
        'Representations and warranties',
        'Payment',
        'Intellectual property',
        'Waiver',
    ],
    [
        'Limitation of liability',
        'Confidentiality',
        'Force majeure',
        'Notices',
        'Separability',
        'Counterparts'
    ],
]
export const firstPart = [
    {
        name: "Name the parties to the contract : ",
        placeholder: "Include as many as you want (including roles of each Party), separated by commas. For example - John Smith (as Contractor), ABC Limited (as Employer)",
        id: "partiesName",
    },
    {
        name: " Mention the party in whose favour the contract should be drafted : ",
        placeholder: "Leave blank if you would like a balanced contract",
        id: "favourParty",
    },
    {
        name: "Governing Law : ",
        placeholder: "Mention country and/or state whose laws should apply to the contract",
        id: "governingLaw",
    },
    {
        name: "Jurisdiction : ",
        placeholder: "Mention the courts which will judge disputes related to the contract",
        id: "jurisdiction",
    },
]

export const secondPart = [
    {
        name: "Subject matter of the contract :",
        placeholder: "Example :  Purchasing 1000 kgs of cement every year for a period of 5 years at the rate of GBP 7 per kg. The quality of cement supplied shall meet [ABCD specifications]. The place of delivery will be Birmingham, United Kingdom. Delivery schedule will be agreed between the parties based on the Purchaser’s requirements. Payments will be made quarterly against invoices raised by the Seller.",
        id: "subjectMatter",
    },
    {
        name: "Default provisions :  Mention implications of a party not complying with their obligations/ responsibilities under the contract.",
        placeholder: "Example :  If the Seller fails to deliver the timber on the agreed delivery date, the Seller will pay to the Buyer as damages 1% of the total contract amount for every week of delay in making the delivery.",
        id: "defaultProvision",
    },
]
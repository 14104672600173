import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import UserService from '../services/UserService'
const ErrorPage = () => {
  const [buttonState, setButtonState] = useState(false)
  useEffect(() => {
    if (UserService.isLoggedIn()) setButtonState(false)
    else setButtonState(true)
  }, [])
  return (
    <div className='text-center text-dark'>
      <h1 style={{ fontSize: "150px", marginTop: "10vh" }}>404</h1>
      <h3>Page Not Found</h3>
      <p>The resource requested could not be found on this server!</p>
      <Link className="btn btn-primary mx-4" to="/app">Home</Link>
      <button className={buttonState ? "btn btn-primary" : "d-none"} onClick={() => UserService.doLogin({ redirectUri: window.location.href })}>Login</button>
      <br />
      <br />
    </div>
  )
}

export default ErrorPage
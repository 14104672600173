export const data1 = [
    {
        name: "Name the parties to the contract : (optional)",
        placeholder: "Include as many as you want (including roles of each Party), separated by commas. For example - John Smith (as Service Provider), ABC Limited (as Client)",
        id: "parties",
    },
    {
        name: "Description of services: (optional) ",
        placeholder: "Example: Software development services which includes: 3 months of discovery and implementation, followed by an annual maintenance obligation.  ",
        id: "description",
    },
   
]

export const data2 = [
    {
        name: "Exclusions (from scope of services): (optional) ",
        placeholder: "Any specific things to be excluded from scope? Example: No software upgrades, which will be charged separately.  ",
        id: "exclusions", 
    }
]
export const data3 = [
    {
        name: "Specify the term of the contract : (optional)",
        placeholder: "in years, months, days; linked to completion of services or any other benchmark",
        id: "contract_term",
    },
]

export const data4 = [
    {
        name: "Any specific obligations for the service provider: (or recipient)?",
        placeholder: "Example: The client shall provide the service provider with access to the site between 12 pm and 4 pm on Mondays and Fridays to enable the service provider to undertake maintenance. ",
        id: "obligations",
    },
    {
        name: "Payment Terms: (optional)",
        placeholder: "Specify the payment to be made to the service provider including monthly/ annual sums, performance incentives etc. Also describe the due dates for such payment, mode of payment, deductions and reimbursements, if any.  ",
        id: "payment_terms",
    },
    {
        name: "Any other terms : (optional)",
        placeholder: "Add any other terms that you think might be relevant. Such as consequences of not meeting performance benchmarks or milestones etc.  ",
        id: "other_terms",
    },

    {
        name: " Governing Law: (optional)",
        placeholder: "Mention the name of your country or state whose laws will apply. Examples: UAE. California. ",
        id: "governing_law",
    },
    {
        name: "Jurisdiction: (optional)        ",
        placeholder: "Name your city whose courts will resolve disputes (Example: Tokyo. Hong Kong. London. San Francisco.)",
        id: "jurisdiction",
    },

  
]

export const checkBoxArray = [
    []
   
]

